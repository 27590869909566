import { createContext, useState, useContext } from "react";
import getArticlesQuery from "../graphql/getArticlesQuery";
import GraphQLRequest from "Utilities/GraphQLClient/GraphQLClient";

const ToolboxContext = createContext();

const ToolboxProvider = ({ initialState, children }) => {
  const [context, setContext] = useState(initialState);

  const onPageChange = async (pageNumber) => {
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    try {
      const discoveryResponse = await GraphQLRequest(
        "/graphql/discovery",
        getArticlesQuery,
        {
          request: {
            sortOrder: "MostRecent",
            query: "*",
            page: pageNumber,
            resultsPerPage: 10,
            userInfo: {
              userVinCookie: "toolboxArticles",
            },
          },
        }
      );
      const { pagination, articles } = discoveryResponse.articleSearch;
      setContext((state) => {
        return {
          ...state,
          isLoading: false,
          articles,
          pagination,
        };
      });
    } catch (err) {
      console.error(err);
      setContext((state) => {
        return {
          ...state,
          isLoading: false,
          articles: [],
        };
      });
    }
  };

  return (
    <ToolboxContext.Provider value={{ ...context, onPageChange }}>
      {children}
    </ToolboxContext.Provider>
  );
};

const useToolbox = () => {
  const context = useContext(ToolboxContext);
  return context;
};

export { ToolboxProvider, useToolbox };
