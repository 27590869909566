import Panel from "Clutch/Atoms/Panel";
import Gutters from "Clutch/Atoms/Gutters";
import styles from "./AboutToolbox.module.scss";
import Typography from "Clutch/Atoms/Typography";

const AboutToolbox = () => {
  return (
    <Gutters>
      <Panel className={styles.container} layer={0}>
        <Typography size={1.5} font={"bold"} tone={"subtle"}>
          What is the Toolbox?
        </Typography>
        <Typography size={1} font={"bold"} tone={"subtle"}>
          Dig through our Toolbox to learn how-to! Speedway Motors automotive
          blog is packed with how-to videos, automotive tech articles, and
          expert advice to help you with your project or race car setup. Weve
          leveraged our 60+ years of performance parts expertise to compile
          hundreds of technical car articles in one easy-to-reference place.
          Whether your passion is race car setup and preparation, perfecting
          your showstopper, or simply improving driveability on your old car or
          truck - we have the technical car articles for you. Learn the best
          tips and tricks from our in-house experts who spend their nights
          wrenching in the garage and racing at the track. This automotive blog
          has everything you need to empower your passion.{" "}
        </Typography>
      </Panel>
    </Gutters>
  );
};

export default AboutToolbox;
