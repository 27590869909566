const getArticlesQuery = `query GetArticles($request: ArticleSearchRequestInput = null){
    articleSearch(request: $request) {
      pagination {
        pageNumber
        pageSize
        totalResults
      }
      articles {
        articleId
        articleDetails {
          title
          authors
          authorId
          description
          deployDate
          createdAt
          versionId
          id
          url
          articleThumbnailUrl
          articleLargeThumbnailUrl
          tags{
            customTags {
              id
              name
              isSeries
            }
          }
        }
      }
    }
  }`;

export default getArticlesQuery;
