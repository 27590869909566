import styles from "./ArticleCard.module.scss";
import Image from "next/image";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import Skeleton from "Clutch/Atoms/Skeleton";

const ArticleCard = ({ article, isLoading }) => {
  const createdAtDate = new Date(article.createdAt);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton fullWidth height={275} />
      ) : (
        <>
          <div>
            <Image
              src={article.articleLargeThumbnailUrl}
              width={448}
              height={275}
              className={styles.image}
            />
          </div>
          <div className={styles.contentContainer}>
            <TextLink
              size={1.25}
              font={"bold"}
              tone={"subtle"}
              href={article.url}
            >
              {article.title}
            </TextLink>
            <Typography size={0.75} tone={"subtle"}>
              By {article.authors}
            </Typography>
            <Typography size={0.75} tone={"subtle"}>
              {`${createdAtDate.getMonth()}/${createdAtDate.getDay()}/${createdAtDate.getYear()}`}
            </Typography>
            <Typography size={0.75} tone={"subtle"}>
              {article.description}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default ArticleCard;
