import Panel from "Clutch/Atoms/Panel";
import Gutters from "Clutch/Atoms/Gutters";
import styles from "./ArticleSection.module.scss";
import Typography from "Clutch/Atoms/Typography";
import ArticleCard from "../article-card/ArticleCard";
import { useToolbox } from "../../providers/ToolboxProvider";
import Pagination from "../pagination/Pagination";

const ArticleSection = () => {
  const { articles, isLoading, pagination, onPageChange } = useToolbox();

  return (
    <Gutters>
      <Panel className={styles.container} layer={0}>
        <Typography size={1.5} font={"bold"} tone={"subtle"}>
          Latest Articles
        </Typography>
        {articles.map((article) => (
          <ArticleCard
            key={article.articleId}
            article={article.articleDetails}
            isLoading={isLoading}
          />
        ))}
        <Pagination
          onPageChange={(pageNunber) => {
            onPageChange(pageNunber);
          }}
          currentPage={pagination.pageNumber}
          pageSize={pagination.pageSize}
          totalCount={pagination.totalResults}
        />
      </Panel>
    </Gutters>
  );
};

export default ArticleSection;
