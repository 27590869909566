import styles from "./CalloutSection.module.scss";
import Card from "Clutch/Atoms/Card";
import Gutters from "Clutch/Atoms/Gutters";
import Typography from "Clutch/Atoms/Typography";

const CalloutSection = () => {
  return (
    <Gutters>
      <div className={styles.container}>
        <Card
          layer={0}
          fill
          invert
          image={
            "https://content.speedwaymotors.com/OtherImages/_MG_8833_400x223_optimized_new.jpg"
          }
          href={"https://www.speedwaymotors.com/the-toolbox/street~8388608-1"}
        >
          <div className={styles.contentContainer}>
            <Typography size={1} font={"bold"} tone={"contrast"}>
              Street Articles
            </Typography>
          </div>
        </Card>
        <Card
          layer={0}
          fill
          invert
          image={
            "https://content.speedwaymotors.com/OtherImages/NEW_Buck_Monson_400x223_optimized.jpg"
          }
          href={"https://www.speedwaymotors.com/the-toolbox/racing~8388608-2"}
        >
          <div className={styles.contentContainer}>
            <Typography size={1} font={"bold"} tone={"contrast"}>
              Race Articles
            </Typography>
          </div>
        </Card>
        <Card
          layer={0}
          fill
          invert
          image={
            "https://content.speedwaymotors.com/OtherImages/w2w_truck_400_optimized.jpg"
          }
          href={"https://www.speedwaymotors.com/the-toolbox/truck~8388608-3"}
        >
          <div className={styles.contentContainer}>
            <Typography size={1} font={"bold"} tone={"contrast"}>
              Truck Articles
            </Typography>
          </div>
        </Card>
      </div>
    </Gutters>
  );
};

export default CalloutSection;
