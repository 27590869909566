import Banner from "../components/banner/Banner";
import Gutters from "Clutch/Atoms/Gutters";
import { revalidationTime } from "../constants/revalidationTime";
import getArticlesQuery from "../graphql/getArticlesQuery";
import GraphQLRequest from "Utilities/GraphQLClient/GraphQLClient";
import Panel from "Clutch/Atoms/Panel";
import AboutToolbox from "../components/about-toolbox/AboutToolbox";
import ArticleSection from "../components/article-section/ArticleSection";
import CalloutSection from "../components/callout-section/CalloutSection";
import { ToolboxProvider } from "../providers/ToolboxProvider";

export async function getStaticProps() {
  try {
    const discoveryResponse = await GraphQLRequest(
      process.env.DISCOVERY_WEBSERVICE_URL,
      getArticlesQuery,
      {
        request: {
          sortOrder: "MostRecent",
          query: "*",
          page: 1,
          resultsPerPage: 10,
          userInfo: {
            userVinCookie: "toolboxArticles",
          },
        },
      }
    );
    const { pagination, articles } = discoveryResponse.articleSearch;

    return {
      props: {
        initialState: {
          articles,
          pagination,
          isLoading: false,
        },
      },
      revalidate: revalidationTime,
    };
  } catch (err) {
    console.error(err);
    return {
      props: {
        initialState: { articles: [], isLoading: false, pagination: null },
      },
      revalidate: revalidationTime,
    };
  }
}

export default function Home({ initialState }) {
  return (
    <ToolboxProvider initialState={initialState}>
      <Panel layer={1}>
        <Banner />
        <CalloutSection />
        <AboutToolbox />
        <ArticleSection />
      </Panel>
    </ToolboxProvider>
  );
}
