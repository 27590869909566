import styles from "./Banner.module.scss";
import Image from "next/image";
import Typography from "Clutch/Atoms/Typography";
import Panel from "Clutch/Atoms/Panel";
import classNames from "classnames";

const Banner = () => {
  return (
    <Panel layer={0} inverseTheme>
      <div className={styles.container}>
        <Image
          src={
            "https://content.speedwaymotors.com/OtherImages/Toolbox_MainBanner_1903x450.jpg"
          }
          width={1903}
          height={450}
          sizes="100vw"
          className={styles.image}
        />
        <div className={styles.titleContainer}>
          <Typography
            className={styles.title}
            invert
            size={4}
            font={"bold"}
            tone={"contrast"}
          >
            READ. LEARN. BUILD.
          </Typography>
        </div>
      </div>
    </Panel>
  );
};

export default Banner;
