import styles from "./PaginationElement.module.scss";
import Typography from "Clutch/Atoms/Typography";
import Card from "Clutch/Atoms/Card";

const PaginationElement = ({ label, isActive, handleOnClick, isDisabled }) => {
  console.log(isDisabled);
  return (
    <Card
      layer={isActive ? 1 : 0}
      onClick={handleOnClick ? handleOnClick : null}
    >
      <Typography
        size={1}
        font={"bold"}
        tone={isDisabled === true ? "superfluous" : "subtle"}
      >
        {label}
      </Typography>
    </Card>
  );
};

export default PaginationElement;
