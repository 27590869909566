import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "../../hooks/usePagination";
import styles from "./Pagination.module.scss";
import Typography from "Clutch/Atoms/Typography";
import PaginationElement from "./pagination-element/PaginationElement";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={styles.container}>
      <PaginationElement
        label={"Prev"}
        handleOnClick={currentPage === 1 ? null : onPrevious}
        isDisabled={currentPage === 1}
      />
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <PaginationElement key={"..."} label={"..."} />;
        }

        return (
          <PaginationElement
            label={pageNumber}
            key={pageNumber}
            handleOnClick={() => onPageChange(pageNumber)}
            isActive={currentPage === pageNumber}
          />
        );
      })}
      <PaginationElement
        label={"Next"}
        handleOnClick={currentPage === lastPage ? null : onNext}
        isDisabled={currentPage === lastPage}
      />
    </div>
  );
};

export default Pagination;
